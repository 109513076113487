<template>
  <v-card>
    <v-card-title>
      {{ this.$t('tablebee.lang_qr_code_generator') }}

      <v-spacer/>

      <v-btn :loading="note_loading" @click="getNote" icon color="error">
        <v-icon>comment</v-icon>
      </v-btn>
      <b-dropdown v-if="selectedItems.length>0" no-caret right toggle-class="btn-icon btn-icon-only" variant="link">
        <span slot="button-content"><i class="pe-7s-menu btn-icon-wrapper"></i></span>
        <div>
          <h6 class="dropdown-header" tabindex="-1">
            {{ $t('generic.lang_Options') }}</h6>
          <button @click="BulkChangeStatus(true)" class="dropdown-item" tabindex="0" type="button"
          >
            <v-icon class="dropdown-icon" color="success">stop</v-icon>
            <span>{{ $t('settings.lang_enable_selected') }}</span>
          </button>

          <button @click="BulkChangeStatus(false)" class="dropdown-item" tabindex="0" type="button"
          >
            <v-icon class="dropdown-icon" color="error">stop</v-icon>
            <span>{{ $t('settings.lang_disable_selected') }}</span>
          </button>

        </div>
      </b-dropdown>
    </v-card-title>
    <v-divider class="ma-0"/>
    <v-card-text>
      <v-data-table
          :loading="this.loading"
          :headers="headers"
          :items="tables"
          :search="search"
          v-model="selectedItems"
          item-key="name"
          show-select
      >
        <template v-slot:top>
          <v-row>
            <v-col cols="12" sm="5">
              <v-text-field
                  v-model="search"
                  :label="$t('tablebee.lang_search_table')"
                  class="mx-4"
                  outlined
                  dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="5">
              <v-select
                  v-model="selectedRoom"
                  :label="$t('tablebee.lang_select_room')"
                  class="pt-0"
                  :items="room"
                  outlined
                  dense
                  @input="filterByRoom()"
              >
                <template v-slot:item="{ item }"><span>{{ item[1] }}</span></template>
                <template v-slot:selection="{ item }"><span>{{ item[1] }}</span></template>

              </v-select>
            </v-col>
            <v-col cols="12" sm="2" v-if="selectedItems.length>0">
              <v-menu
                  bottom
                  origin="center center"
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon dark>
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                      v-for="(item, i) in items"
                      :key="i"
                  >
                    <v-btn
                        class="ma-2"
                        outlined
                        :color="item.color"
                        @click="getQRCode(selectedItems.map(item=>{
                        return {name:item.name}
                      }),item.title.toLocaleLowerCase())"
                    >
                      <v-icon dark>
                        {{ item.icon }}
                      </v-icon>
                      {{ item.title }}
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.generate_uuid="{item}">
          <div class="d-flex">

            <v-text-field @keydown.enter="updateUUID(item)" class="tablebee_uuid_input" @blur="disableEditMode(item)"
                          @focus="onFocusSelect($event)"
                          :readonly="!item.editable"
                          v-model="item.tablebee_uuid"
                          :value="item.tablebee_uuid"
                          hide-details
                          dense outlined>
              <template v-slot:prepend-inner>
                <v-btn text @click="renewUUID(item)" :disabled="item.loading" :loading="item.loading" fab depressed
                       small
                       color="success">
                  <v-icon>refresh</v-icon>
                </v-btn>
              </template>


              <template v-slot:append>
                <v-btn @click="enableEditMode(item)" text v-if="!item.editable" :disabled="item.loading"
                       :loading="item.loading" fab depressed
                       small
                       color="primary">
                  <v-icon>edit</v-icon>
                </v-btn>
                <v-btn v-else @click="updateUUID(item)" :disabled="item.loading"
                       :loading="item.loading" fab depressed
                       small
                       color="success">
                  <v-icon>check</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </div>
        </template>
        <template v-if="selectedItems.length==0" v-slot:item.png="{ item }">
          <v-btn
              fab
              dark
              small
              color="success"
              @click="getQRCode([{name:item.name}],'png')"
          >
            <v-icon dark>
              mdi-download
            </v-icon>
          </v-btn>
        </template>
        <template v-if="selectedItems.length==0" v-slot:item.svg="{ item }">
          <v-btn
              fab
              dark
              small
              color="warning"
              @click="getQRCode([{name:item.name}],'svg')"
          >
            <v-icon dark>
              mdi-download
            </v-icon>
          </v-btn>
        </template>
        <template v-if="selectedItems.length==0" v-slot:item.pdf="{ item }">
          <v-btn
              fab
              dark
              small
              color="error"
              @click="getQRCode([{name:item.name}],'pdf')"
          >
            <v-icon dark>
              mdi-download
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:item.status="{item}">
          <v-switch @change="changeStatus($event , item.tableUUID)" :value="true" v-model="item.status"/>
        </template>
      </v-data-table>
    </v-card-text>


    <!-- inactive table dialog -->
    <v-dialog max-width="700" v-model="message_dialog">
      <v-card>
        <v-card-title>
          {{ this.$t('tablebee.lang_inactive_tables_message') }}
        </v-card-title>

        <v-card-text>
          <v-row>

            <v-col cols="12">

              <quill-editor :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :options="quillOptions"
                            @focus="showTouchKeyboard"
                            output="html" class="mb-4" v-model="form.message">

              </quill-editor>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn :loading="this.note_loading" :disabled="this.note_loading" @click="message_dialog = false" text
                 color="error">
            {{ this.$t('generic.lang_cancel') }}
          </v-btn>

          <v-spacer/>

          <v-btn :loading="this.save_note_loading" :disabled="this.save_note_loading" @click="saveNote" color="primary"
                 depressed>
            {{ this.$t('generic.lang_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </v-card>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import FileSaver from 'file-saver';
import Template from "@/components/settings/customerdisplay/Template";
import mixin from "../../../mixins/KeyboardMixIns";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor';


export default {
  name: "TableManagerComponent",
  components: {Template, quillEditor},
  mixins: [mixin],
  data() {
    return {
      form: {
        message: ""
      },
      save_note_loading: false,
      note_loading: false,
      quillOptions: {

        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': []}],
            [{'font': []}],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ],
        },
      },
      search: '',
      loading: false,
      selectedItems: [],
      message_dialog: false,
      calories: '',
      room: [],
      selectedRoom: null,
      items: [
        {title: 'PNG', icon: 'mdi-file-image', color: 'warning'},
        {title: 'SVG', icon: 'mdi-file-image', color: 'success'},
        {title: 'PDF', icon: 'mdi-file-pdf', color: 'error'},
      ],
      tablesGlobal: [],
      tables: []

    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('generic.lang_name'),
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('tablebee.lang_generate_uuid'),
          value: 'generate_uuid'
        },
        {
          text: this.$t('generic.lang_party'),
          value: 'TotalParties',
        },
        {text: ' PNG', value: 'png', sortable: false, align: 'center'},
        {text: ' SVG', value: 'svg', sortable: false, align: 'center'},
        {text: ' PDF', value: 'pdf', sortable: false, align: 'center'},
        {
          text: this.$t('generic.lang_status'), value: 'status'
        }
      ]
    },
  },
  watch: {
    message_dialog(val) {
      if (!val) this.form.message = ""
    }
  },
  mounted() {
    this.getData();
    this.getRoom();
  },
  methods: {
    enableEditMode(item) {
      item.editable = true;
      this.$forceUpdate();
    },
    disableEditMode(item) {
      item.editable = false;
      this.$forceUpdate();
    },
    onFocusSelect(event) {
      event.target.select();
    },
    updateUUID(item) {
      item.loading = true;
      this.$forceUpdate();
      this.axios.post(ENDPOINTS.TABLEBEE.TABLES.UPDATE_UUID, {
        uuid: item.tableUUID,
        newUuid: item.tablebee_uuid
      })
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_actionSuccessful'),
                color: "success"
              });

              this.getData();
            } else {
              Events.$emit("showSnackbar", {
                message: err.message,
                color: "error"
              });
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        item.loading = false;
        this.$forceUpdate();
      });
    },
    renewUUID(table) {
      table.loading = true;
      this.$forceUpdate();
      this.axios.post(ENDPOINTS.TABLEBEE.TABLES.RENEW_UUID, {
        uuid: table.tableUUID
      })
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_actionSuccessful'),
                color: "success"
              });
            } else {
              Events.$emit("showSnackbar", {
                message: err.message,
                color: "error"
              });
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        table.loading = false;
        this.$forceUpdate();
      });
    },
    changeStatus(status, uuid) {
      this.loading = true;
      this.axios.post(ENDPOINTS.TABLEBEE.TABLES.UPDATE_STATUS, {
        status: status,
        UUID: uuid
      })
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              this.getData();
            } else {
              Events.$emit("showSnackbar", {
                message: err.message,
                color: "error"
              });
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },

    saveNote() {
      this.save_note_loading = true;
      this.axios.post(ENDPOINTS.TABLEBEE.TABLES.UPDATE_STATUS_MESSAGE, {
        inactive_table_message: this.form.message
      })
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              this.message_dialog = false;
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.save_note_loading = false;
      });
    },
    getNote() {
      this.note_loading = true;
      this.axios.post(ENDPOINTS.TABLEBEE.TABLES.GET_STATUS_MESSAGE)
          .then((res) => {
            if (res.data.STATUS === "SUCCESS") {
              this.form.message = res.data.data.inactive_table_message;
              this.message_dialog = true;
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.note_loading = false;
      });
    },
    BulkChangeStatus(status) {

      this.loading = true;
      this.axios.post(ENDPOINTS.TABLEBEE.TABLES.BULK_UPDATE_STATUS, {
        status: status,
        tables: this.selectedItems.map((table) => table.tableUUID)
      })
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              this.getData();
              this.selectedItems = [];
            } else {
              Events.$emit("showSnackbar", {
                message: err.message,
                color: "error"
              });
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    filterOnlyCapsText(value, search, item) {
      return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().indexOf(search) !== -1
    },
    filterByRoom() {
      this.tables = this.tablesGlobal.filter(item => item.room == this.selectedRoom[1]);
    },
    getData() {
      this.loading = true;
      this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.GETALL)
          .then(res => {
            this.tables = res.data.tables;
            this.tablesGlobal = res.data.tables;
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(fin => {
        this.loading = false;
      });
    },
    getRoom() {
      this.loading = true;
      this.axios.post('get/settings/allRooms/')
          .then(res => {
            this.room = res.data.roomInformations;
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(fin => {
        this.loading = false;
      });
    },
    getQRCode(table, type) {
      let formData = {}
      let blobType = '';
      let name = '';
      console.log(table, type)
      switch (type) {
        case 'png':
          formData.tableName = table;
          formData.type = type;
          blobType = (table.length > 1) ? "application/zip" : 'image/png';
          break;
        case 'svg':
          formData.tableName = table;
          formData.type = type;
          blobType = (table.length > 1) ? "application/zip" : 'image/svg';
          break;
        case 'pdf':
          formData.tableName = table;
          formData.type = type;
          blobType = 'application/pdf';
          break;
      }

      if (table.length > 1 && type !== 'pdf') {
        name = 'localbee_table_QRCODES.zip'
      } else if (table.length > 1 && type === 'pdf') {
        name = 'localbee_tables_QRCODES.pdf'
      } else {
        name = table[0].name + '.' + type;
      }
      console.log(table, type, name)

      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.SELFSERVICE.QRCODE, formData, {responseType: 'arraybuffer'})
          .then(res => {
            if (res.status === 200) {
              const blobContent = new Blob([res.data], {type: blobType});
              FileSaver.saveAs(new Blob([res.data], {
                type: blobType
              }), name);
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      })

    },
  },
}
</script>

<style>
.tablebee_uuid_input.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner, .tablebee_uuid_input.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) .v-input__append-inner {
  margin: 0 0 0 0 !important;
}

.tablebee_uuid_input .v-input__slot {
  padding: 0 !important;
}
</style>

