<template>
  <div>
    <page-title :heading="$t('tablebee.tablebeeDesignConfig')"
                :subheading="$t('tablebee.tablebeeDesignConfig')" :icon=icon></page-title>
    <div class="app-main__inner">
      <table-manager-component/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../Layout/Components/PageTitle.vue";
import TableManagerComponent from "@/components/tableBee/tables/TableManagerComponent";


export default {
  name: "TableManagerView",
  components: {
    TableManagerComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-box1 icon-gradient bg-tempting-azure',
  })
}
</script>

